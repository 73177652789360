.speaking {
    display: flex;
    flex-direction: column;
    color: white;
    margin: 0;
    height: max-content;
    background-color: rgb(22, 22, 22);
}

.speaking-header {
    padding: 0 5vw;
}

.speaking-header h1 {
    margin: 0 0 10px 0;
    font-size: 50px;
    font-weight: 500;
    text-transform: none;
    text-align: left;
    letter-spacing: 0px;
    line-height: 72px;
    white-space: pre-line;
    opacity: 1;
    visibility: visible;
    font-family: "Source Sans Pro";
}

.speaking-info-row-wrapper {
    padding: 0 5vw;
    margin: 0;
    height: 655px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgb(22, 22, 22);
    width: auto;

}


.speaking-info-row {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 98%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(22, 22, 22);
}

.speaking-news-block {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
    align-items: center;
}

.speaking-news-vid {
    height: 40%;
    width: 100%;
}

.speaking-news-vid iframe {
    border-radius: 10px;
    background-color: transparent;
}

.speaking-news-desc p {
    width: auto;
    padding-left: 0;
    overflow-wrap: normal;
    font-size: 12px;
    text-align: justify;
    margin: 0 0 20px 0;

}

.speaking-news-desc-sub {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.speaking-news-desc a {
    color: white;
    outline: none;
    cursor: pointer;
}

.speaking-quote-row {
    background-color: rgb(43, 43, 43);
    text-align: center;
    padding: 0 5vw;
}

.speaking-quote-row p {
    color: white;
    flex-direction: column;
    display: flex;
    font-size: 33px;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    font-weight: 400;
}

.speaking-footer-wrapper {
    background-color: rgb(22, 22, 22);
    padding: 0 5vw;
}

.speaking-footer-wrapper p {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    text-align: start;
    margin: 0;
    padding: 0;
    font-size: 21px;
}

.speaking-footer-btn {
    display: flex;
    justify-content: center;
}

.speaking-footer-btn a {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
    width: 245px;
    height: 40px;
    opacity: 1;
    background-color: rgb(0, 0, 0);
    border-radius: 24px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px;
    border: 0px;
    margin: 10px 0;
    text-decoration: none;
    color: white;
}

.speaking-footer-btn a:hover {
    transform: translateY(-2px);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease-in-out;
}

.email-contact {
    width: 100%;
}

.email-contact form {

    display: flex;
    flex-direction: column;
}

.email-contact>label {

    display: flex;
    font-size: 24px;
    font-weight: 300;
    text-transform: none;
    text-align: left;
    letter-spacing: 0px;
    line-height: 26px;
    white-space: pre-line;
    opacity: 1;
    visibility: visible;
    font-family: "Source Sans Pro";
    font-style: italic;
}

.contact-wrapper {

    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.contCol {
    width: 50%;
}

.contRow {
    display: flex;
    margin: 10px 0px;
    width: 90%;
    height: 50px;
    border-radius: 24px;
    box-shadow: rgba(255, 255, 255, 0.1) 2px 2px 4px;
    border: 0px;
}

.contRow input {
    background-color: rgb(43, 43, 43);
    width: 100%;
    padding: 0px 8px;
    color: rgb(183, 183, 183);
    font-weight: 300;
    font-size: 20px;
    border-radius: 24px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: none;

}

.contRow-date {
    display: flex;
    margin: 10px 0px;
    width: 87.1%;
    box-shadow: rgba(255, 255, 255, 0.5) 2px 2px 4px;
    border: 0px;
    background-color: rgb(43, 43, 43);
    height: 50px;
    padding: 0px 8px;
    color: rgb(183, 183, 183);
    font-weight: 300;
    font-size: 20px;
    border-radius: 24px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: none;
    align-items: center;
    justify-content: space-between;
}

.contRow-date input {
    background-color: rgb(43, 43, 43);
    color: rgb(183, 183, 183);
    height: 30px;
    font-size: 15px;
    margin-right: 5px;
}

.form-checkbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
}

.form-checkbox .check-opt {
    display: flex;
    flex-direction: row;
}

.check-opt input {
    border: 0;
    padding: 0;
    margin: 0 3px;
}

.check-opt label {
    font-style: italic;
}

.contact-text textarea {
    padding: 10px 10px;
    color: rgb(183, 183, 183);
    width: 87.1%;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: none;
    background-color: rgb(43, 43, 43);
    border-radius: 24px;
    border: 0;
    box-shadow: rgba(255, 255, 255, 0.1) 2px 2px 4px;

}

.contact-text {
    display: flex;
    opacity: 1;
    outline: none;
    width: 50%;
}

.contact-form-btn button {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
    width: 45%;
    height: 40px;
    opacity: 1;
    background-color: rgb(0, 0, 0);
    border-radius: 24px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px;
    border: 0px;
    margin: 10px 0;
    text-decoration: none;
    color: white;
}


.contact-form-btn {
    display: flex;
    justify-content: center;
    margin: 20px;
}

.contact-form-btn button:hover {
    transform: translateY(-2px);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 480px) {
    .speaking-header {
        display: flex;
        justify-content: center;
    }

    .speaking-info-row {
        flex-direction: column;
    }

    .speaking-news-block {
        width: 100%;
    }

    .speaking-info-row-wrapper {
        height: auto;
    }

    .speaking-news-desc p {
        font-size: 16px;
    }

    .speaking-news-vid {
        height: 25vh;
    }

    .speaking-footer-wrapper p {
        font-size: 18px;
    }

    .speaking-quote-row p {
        font-size: 24px;
    }

    .email-contact {
        width: 100%;
    }

    .contRow input,
    .contRow-date,
    .contRow-date input,
    .contact-text textarea {
        font-size: 13px;
    }

    .contRow-date input {
        font-size: 9px;
        width: 75%;

    }

    .contact-form-btn button {
        width: 100%;
    }
}