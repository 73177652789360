.home-wrapper {
    display: flex;
    flex-direction: column;
    color: white;
    padding: 0;
    margin: 0;
    width: 100%;
    height: max-content;
}

.banner {
    background-color: black;
}

.banner .banner-video {
    height: 785px;
}


.banner h1 {
    padding: 30px 0;
    text-wrap: wrap;
    font-size: 44px;
}




.quote-wrapper {
    background-color: rgb(43, 43, 43);
    display: flex;
    position: relative;
    height: 285px;
    width: 100%;
    z-index: 12;
    justify-content: center;
}

.quote-wrapper p {
    color: white;
    flex-direction: column;
    display: flex;
    font-size: 33px;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    font-weight: 600;
}



.quote-name {
    font-size: 20px;
    font-weight: 300;
}



.project-row-wrapper {
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
    background-color: rgb(43, 43, 43);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}



.project-row-wrapper div {
    width: 30vw;
    height: 458px;
    display: grid;
    justify-items: center;
    align-content: space-between;
}


.project-row-wrapper p {
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 1px 5px;
    margin: 10px;
    position: relative;
    top: -90px;
    width: max-content;
    align-self: center;
}


.project-row-wrapper label {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 1px 5px;
    margin: 10px;
    position: relative;
    top: -90px;
    width: max-content;
    align-self: center;
}

.info-row-wrapper {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 735px;
    background-color: rgb(43, 43, 43);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.info-row-wrapper label {
    background-color: black;
    text-align: left;
    padding: 50px 100px;
    font-size: 30px;
}



.info-row {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 584px;
    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}



.news-block {
    display: flex;
    flex-direction: column;
    width: 48%;
    height: 100%;
    align-items: center;
}


.news-vid {
    height: 70%;
    width: 80%;
}

.news-vid iframe {
    border-radius: 10px;
    background-color: black;
}

.news-desc p {
    width: auto;
    padding-left: 11%;
    overflow-wrap: normal;
    font-size: 12px;
    text-align: justify;
    margin: 0 0 20px 0;
}



.news-desc-sub {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.project-1>*,
.project-2>*,
.project-3>* {
    cursor: pointer;
}

.project-1 img {
    border-radius: 20px;
    width: 100%;
    height: 458px;
}


.project-2 img {
    border-radius: 20px;
    width: 100%;
    height: 458px;
}

.project-3 img {
    border-radius: 20px;
    width: 100%;
    height: 458px;

}

@media only screen and (max-width: 480px) {
    .news-desc p {
        font-size: 16px;
        padding: 0 20px;
    }

    .news-block {
        width: 100%;
    }

    .news-vid {
        height: 20vh;
    }

    .info-row {
        flex-direction: column;
        height: auto;
    }

    .info-row-wrapper {
        height: auto;
    }

    .info-row-wrapper label {
        font-size: 23px;
        text-align: center;
    }

    .project-row-wrapper p {
        font-size: 15px;
        margin: 10px;
        position: relative;
        top: -90px;
        width: max-content;
        align-self: center;
    }

    .project-row-wrapper label {
        align-self: center;
        width: max-content;
        position: relative;
        top: -90px;
    }

    .project-row-wrapper div {
        width: 100%;
        height: inherit;
        display: contents;
    }

    .project-row-wrapper {
        flex-direction: column;
        /* height: 1200px; */
    }

    .quote-name {
        font-size: 15px;
    }

    .quote-wrapper p {
        font-size: 22px;
    }

    .banner h1 {
        font-size: 33px;
    }

    .banner .banner-video {
        height: 385px;
    }
}