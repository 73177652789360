.footer-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    bottom: 0;
    height: max-content;
    background-color: rgb(43, 43, 43);
    color: white;
    padding: 2vh 50px 5vh;
    margin: 0;
}

.footer-header h3 {
    text-align: center;
    color: white;
    font-size: 44px;
    text-transform: capitalize;
    margin: 1vh 0;
    padding: 0;

}

.footer-desc {
    text-align: start;
    margin: 1vh 0;
    text-align: center;
    height: min-content;
}

.footer-desc p {
    font-size: 24px;
    margin-top: 0;
}

.footer-social-wrapper ul {
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    padding: 0;
}

.footer-social-wrapper .footer-social li a {
    display: flex;
    font-size: 100px;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    flex-direction: column;
    color: white;
    text-decoration: none;
}

.footer-social span {
    font-size: 14px;
}

.contact-form {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.social-contact {
    width: 50%;
}


.footer-calendar {
    position: relative;
    background-color: rgb(43, 43, 43);
    height: min-content;
    min-width: 75%;

}

.footer-calendar abbr {
    text-decoration: none;
}

@media only screen and (max-width: 480px) {

    .email-contact,
    .social-contact {
        width: 100%;
    }

    .contact-form {
        flex-direction: column;

    }

    .footer-wrapper {
        padding: 2vh 2vh 5vh;
    }
}