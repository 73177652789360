.edentity {
    display: flex;
    flex-direction: column;
    height: max-content;
    background-color: rgb(22, 22, 22);
    color: white;
    align-items: flex-start;
    padding: 0 5vw;
}

.edentity-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
}

.edentity-header h3 {
    font-style: italic;
    margin: 0px;
    font-size: 20px;
    font-weight: 300;
    text-transform: none;
    text-align: left;
    letter-spacing: 0px;
    line-height: 26px;
    white-space: pre-line;
    opacity: 1;
    visibility: visible;
    font-family: "Source Sans Pro";
}

.edentity-header h1 {
    margin: 0px;
    font-size: 50px;
    font-weight: 500;
    text-transform: none;
    text-align: left;
    letter-spacing: 0px;
    line-height: 72px;
    white-space: pre-line;
    opacity: 1;
    visibility: visible;
    font-family: "Source Sans Pro";
}

.edentity-body {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;
    align-items: center;
}

.edentity-text {
    display: flex;
    flex-direction: column;
    width: 59%;
}

.edentity-text p {
    font-size: 20px;
    font-weight: 400;
    text-transform: none;
    text-align: justify;
    letter-spacing: 0px;
    line-height: 28px;
    white-space: pre-line;
    opacity: 1;
    visibility: visible;
    margin: 20px 0 0 0;
}

.edentity-img {
    width: 39%;
}

.edentity-img img {
    width: 100%;
}

.edentity-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 50px 0;
    align-items: center;
}

.footer-btn a {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
    width: 504px;
    height: 46px;
    opacity: 1;
    background-color: rgb(0, 0, 0);
    border-radius: 24px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px;
    border: 0px;
    margin: 10px 0;
    text-decoration: none;
    color: white;
}

.footer-btn a:hover {
    transform: translateY(-2px);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease-in-out;
}

.edentity-footer h2 {
    font-size: 40px;
    font-weight: 400;
}

@media screen and (max-width: 480px) {
    .edentity-header {
        width: 100%;
        align-items: center;
    }

    .edentity-text,
    .edentity-img {
        width: 100%;
    }

    .edentity-body {
        flex-direction: column-reverse;
    }

    .footer-btn a {
        width: 370px;
    }
}