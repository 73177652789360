.about {
    display: flex;
    flex-direction: column;
    height: max-content;
    background-color: rgb(22, 22, 22);
    color: white;
    align-items: flex-start;
    padding: 0 5vw;
}

.about-desc {
    display: flex;
    align-items: flex-start;
}

.about-desc p {
    width: 60%;
    text-align: left;
}

.about-img {
    width: 25%;
    margin: 0 30px;
}

.about-img img {
    border-radius: 10px;
    width: 100%;
}

.about-char {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.about-char ul {
    width: 65%;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    flex-wrap: wrap;
}

.about-char li {
    padding: 5px 20px;
    background-color: black;
    border-radius: 20px;
    margin: 0 10px 10px 0
}

@media screen and (max-width: 480px) {
    .about {
        align-items: center;
    }

    .about-desc {
        align-items: center;
        flex-direction: column-reverse;
    }

    .about-desc p,
    .about-img {
        width: 100%;
        margin: 10px 0;
        font-size: 18px;
    }
}