body {
    margin: 0;
}

.topnav {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    position: relative;
    margin: 0;
    width: 100%;
    position: sticky;
    top: -1px;
    z-index: 99;
    height: min-content;
    padding: 10px 0;
}

.nav-logo li {
    float: left !important;

}


.nav-logo li {
    margin: 5px 5px 5px 15px !important;
}

ul.topnav {
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    background-color: rgb(43, 43, 43);
}

ul.topnav li {
    float: right;
    margin: 5px;
}

ul.topnav li a {
    display: block;
    color: white;
    text-align: center;
    padding: 4px 10px;
    text-decoration: none;
    border-radius: 20px;
}

ul.topnav li :hover {
    transform: translateY(-2px);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease-in-out;
}

ul.topnav li a:hover:not(.active) {
    background-color: #111;
}

ul.topnav li a.active {
    background-color: darkgray
}

ul.topnav li.right {
    float: right;
    margin-right: 15px;
}


.active {
    background-color: darkgray
}

.bar-menu {
    display: none;
    font-size: 24px;
    position: absolute;
    top: 0px;
    right: 15px;
    padding: 5px 10px;
    border-radius: 30px;
    background-color: rgb(22, 22, 22);
    color: white;
    position: absolute;
    top: 10px;
    right: 15px;
}



@media screen and (max-width: 480px) {

    ul.topnav li.right,
    ul.topnav li {
        float: none !important;
        margin: 5px 115px !important;
    }

    .topnav {
        flex-direction: column-reverse;
    }

    .bar-menu {
        display: block;
    }

    .disable {
        display: none;
        transition: display 1s;
        transition-timing-function: ease-in-out;
    }

    .enable {
        display: flex;
        flex-direction: column-reverse;
        transition: display 1s;
        transition-timing-function: ease-in-out;
    }
}